import { getBackgroundCSS, getBorderCSS, getIconCSS, getShadowCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, id }) => {
	const { socials, alignment, background, size, padding, margin, border, shadow } = attributes;

	const mainSl = `#${id}`;
	const liSl = `${mainSl} ul.bssbSocialShare li.icon`;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${mainSl}{
				text-align: ${alignment};
			}
			${liSl}{
				font-size: ${size};
				${getBackgroundCSS(background)}
				width: calc( ${size} + ${padding} + ${padding} );
				height: calc( ${size} + ${padding} + ${padding} );
				margin: 0 calc( ${margin} / 2 );
				${getBorderCSS(border)}
				box-shadow: ${getShadowCSS(shadow)};
			}
			${liSl} img{
				width: ${size};
			}
			`.replace(/\s+/g, ' ')
		}} />

		<style>
			{socials?.map((item, index) => `
				${liSl}-${index} i{
					${getIconCSS(item?.icon)}
					font-size: inherit;
				}`.replace(/\s+/g, ' ')
			)}
		</style>
	</>
}
export default Style;